@use 'mixins/media-queries.scss' as *;

/* ============ VARIABLES ============= */
:root {
    --clr-creations-bg: #2b2b2b;
    --img-creations-bg: url('../assets/creations-bg.webp');

    --clr-videos-bg: #26282a;
    --img-videos-bg: url('../assets/videos-bg.webp');

    --clr-github-bg: #0d1117;

    --clr-itch-bg: #ff2449;

    --clr-discord-bg: #7289da;
    --clr-discord-btn: #23272a;
    --clr-discord-btn-hover: #36393f;
}

/* ============ CREATIONS ============= */
#creationsSection {
    background-image: var(--img-creations-bg);
    background-position-x: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: var(--clr-light);
}

#creationsLeft {
    margin: 5.5rem 2rem 1rem;

    @include minW(40rem) {
        text-align: right;
        margin: auto 2rem;
    }
}

#creationsRight {
    text-align: center;
    margin: 1rem 3rem 2rem;

    @include minW(40rem) {
        text-align: left;
        margin: auto 2rem;

        p {
            max-width: 12rem;
        }
    }
}

.btn-creations {
    --behind-color: var(--clr-creations-bg);
}

/* ============== VIDEOS ============== */
#videosSection {
    background-image: var(--img-videos-bg);
    background-position-x: center;
    background-size: cover;
    background-repeat: repeat-y;
    background-attachment: fixed;
    color: var(--clr-light);
}

#videosLeft {
    text-align: center;
    margin: 4.5rem 3rem 1rem;

    @include minW(40rem) {
        text-align: right;
        margin: auto 2rem;
        display: flex;
        flex-flow: row-reverse;

        p {
            max-width: 12rem;
        }
    }
}

#videosRight {
    margin: 1rem 2rem 2rem;

    @include minW(40rem) {
        text-align: left;
        margin: auto 2rem;
    }
}

.btn-videos {
    --behind-color: var(--clr-videos-bg);
}

/* ============== GITHUB ============== */
#githubSection {
    background-color: var(--clr-github-bg);
}

.btn-github {
    --focus-color: var(--clr-github-accent);
    --behind-color: var(--clr-github-bg);

    color: var(--clr-github-accent);
    background-color: var(--clr-github-btn);
    border: .0625rem solid var(--clr-github-btn);
    transition-duration: .2s;

    &:hover {
        background-color: var(--clr-github-btn-hover);
        border: .0625rem solid var(--clr-github-accent);
    }
}

/* =============== ITCH =============== */
#itchSection {
    background-color: var(--clr-itch-bg);
}

.btn-itch {
    --focus-color: var(--clr-light);
    --behind-color: var(--clr-itch-bg);

    color: var(--clr-light);
    background-color: var(--clr-transparent);
    border: 0.125rem solid var(--clr-light);

    &:hover {
        color: var(--clr-itch-bg);
        background-color: var(--clr-light);
    }
}

/* ============== DISCORD ============= */
#discordSection {
    background-color: var(--clr-discord-bg);
}

.btn-discord {
    --focus-color: var(--clr-discord-btn);
    --behind-color: var(--clr-discord-bg);

    color: var(--clr-light);
    background-color: var(--clr-discord-btn);

    &:hover {
        background-color: var(--clr-discord-btn-hover);
    }
}
